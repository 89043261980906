/** 
 * 大数据分析---行业解决方案
 */
  <template>
  <v-app>
    <v-banner
      single-line
      height="400"
      class="banner-one"
      elevation="0"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/other/bigdatahangyeBanner.png"
          height="100%"
          :transition="false"
          class="d-flex align-center">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center align-center">
          <v-card color="transparent" flat width="80%" class="d-flex flex-no-wrap justify-center">
            <div >
              <div>
                <v-card-title class="banner-title white--text font-size-48 mb-6 pa-0 justify-center text-align-center">
                  为行业用户<br/>注入数据驱动基因
                </v-card-title>
                <!-- <v-card-title class="banner-text justify-center px-8 pa-0">
                  简单易用，视听业务更安全合规，让企业安心开展直播、点播业务
                </v-card-title>
                <div class="btnWrap">
                    <p class="banner-btn">立即咨询</p>
                </div> -->
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <v-card flat class=" pt-14" >
      <v-tabs v-model="tab" centered background-color="transparent" class="g-tabs font-size-20">
        <v-tab v-for="(v, k) in data.wrap1.list" :key="k">
          <b>{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="background-color: transparent">
        <v-tab-item v-for="(v, k) in data.wrap1.list" :key="k"
                    :transition="false">
          <v-card 
              class="wrap d-flex d-flex part-one flex-no-wrap justify-center align-center"
              :class="m.direction == true? ' flex-row-reverse justify-end text-align-right':''"
              flat
              v-for="(m,n) in v.children" :key="n"
            >
              <div class="justify-center mx-10">
                <img :src="m.img" />
              </div>
              <div class="text-wrap mx-10 align-center">
                <v-card flat >
                  <v-card-title class="con_tit  pa-0 " v-text="m.upTit" :class="m.direction == true? 'justify-end':''"></v-card-title>
                  <v-card-text  class="con_tip  pa-0 font-size-18  mt-2" v-text="m.untip"></v-card-text>
                  <v-card-text  class="con_text pa-0 font-size-14 " v-text="m.upCon"></v-card-text>
                </v-card>
              </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- 相关产品 -->
    <div class="first">
      <div class="public-title" style="margin-top:40px;">
          <p>PRODUCT</p>
          <div>
            <span>相关产品</span>
          </div>
      </div>
      <div class="con">
        <v-row no-gutters justify='space-between'>
          <v-col v-for="(v, k) in data.scenes" :key="k" cols="12" sm="4" >
            <v-card class="cardbox" :style="v.style" :to="v.router">
              <v-card-title class="font-weight-bold pt-10 white--text font-size-24"> {{ v.title }} </v-card-title>
              <v-card-text class="tip"> {{ v.content }} </v-card-text>
              <v-img :src='v.img' height="180px"/>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>

  </v-app>
</template>

<script>
import contentData from '@/static/contentData.js';

export default {
  name: "industrySolution",
  data: () => ({
    data:contentData.industrySolution,
    tab: null,
    direction:false,
  }),
  methods:{
  }
}
</script>

<style lang="scss" scoped>
.g-tabs .v-tab{
  padding: 0 110px;
}
  .part-one{
    padding: 30px 0px;
    .rowflex{
      text-align: end;
    }
    img{
      border-radius: 10px;
      width: 400px;
      height: 260px;
      object-fit: contain;
      // padding: 0 25px;
    }
    .text-wrap{
      width: 400px;
      padding: 0px 25px;
      align-items: center;
      display: flex;
      .con_tit{
        font-size: 24px;
        color: #333333;
        font-weight: 500;
        line-height: 34px;
      }
      .con_tip{
        color: #333333;
      }
      .con_text{
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
        height: unset;
        margin-top: 16px;
      }
      .banner-btn {
        margin-top: 44px;
        width: 160px;
        height: 50px;
        background-color: #0568FD;
        border-radius: 25px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  ::v-deep.first {
  width: 1200px;
  margin: 0 auto;
  .con {
    margin-top: 10px;
    margin-bottom: 60px;
  }
  .cardbox{
    margin: 0 auto;
    width: 300px;
    height: 392px;
    border-radius: 10px;
    
    .v-image__image--cover{
      background-size: initial !important;
    }
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    color: #fff;
   
    img {
      width: 120px;
      height: 120px;
  
    }
  }
  .tip {
    width: 272px;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 50px;
  }
}

</style>
